<template>
     <MobileHeader :mobileHeaderTitle="mobileHeaderTitle" ></MobileHeader>
        <div class="home has-header container">
            <div class="info-logo__wrapper">
                <div class="info-logo__container">
                    <Icon  :category="'player'" :logoUrl=playerInfo.logo  class="info-logo__img"></Icon>
                    <!-- <img class="team-info__header-logo" src="../../../../static/images/icons/icon_goal_player.png"> -->
                </div>
                <div class="team-info__header-title">{{playerInfo.name}}</div>
            </div>

            <div class=" display-flex has-space mb-2rem">
                <router-link class="sub-nav__child" :to="{path:`${o.path}`}"  v-for="(o, index) in tabs" :key="index" >
                    <span  class="sub-nav__child-text">{{$t(o.name)}}</span>
                    <div class="sub-nav__child-indicator"></div>
                </router-link>
            </div>

             <div class="">
                <router-view></router-view> 
            </div>
        </div>
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import MobileHeader from '@/components/mobile/Header.vue';

export default {
 components: {
    MobileHeader,

  },
  data() {
      return {
          mobileHeaderTitle:'PLAYER',
          playerKey:'',
            tabs:[
                {
                    name:'OVERVIEW',
                    path:'overview',
                    value:1,
                    selected:true
                },
                // { // YIM - AS-1041
                //     name:'CAREER',
                //     value:2,
                //     path:'career',
                //     selected:false
                // },
                {
                    name:'TRANSFER',
                    path:'transfer',
                    value:3,
                    selected:false
                },

            ],     
        }
  },
  created() {
      this.playerKey = this.$route.params.player;
  },

    computed:{
            ...mapGetters([              
                'isMobile',
                'playerInfo',
            ]),
    },
  mounted(){
      this.getPlayerInfoData();
  },
  methods:{
        ...mapActions([
        "getBasketballPlayerInfo",
    ]),

    getPlayerInfoData(){
        let params = {
            playerId : this.playerKey
        }
        this.getBasketballPlayerInfo(params);
    }
  }
}
</script>

<style scoped>
    .team-info__header-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .team-info__header-title{
        font-size:1.2rem;
        font-weight: 700;
    }
    .team-info__overview-card{
        margin-bottom: 2rem;
    }

     .team-info__overview-header{
        margin: 0 1rem;
         margin-bottom: 1.45rem;
         font-size: .87rem;
         font-weight: 400;
     }
    .team-info__overview-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: .7rem;
        padding: .8rem 0.5rem;
        border-bottom: .1rem solid #212B30;
        color: var(--color-grey-light);
        margin: 0 .5rem;
    }
    .team-info__overview-body__header{
        display: flex;
        justify-content: space-between;
        background-color: var(--color-grey-20);
        padding: 0.5rem 1rem;
    }
    .team-info__overview-body:last-child{
         border-bottom:none
    }
    .team-info__overview-goal__player{
        display:flex;
        align-items: center;
        font-size: .85rem;
        flex:2
    }
    .team-info__overview-goal__player-rank{
        margin-right: .8rem;
        width: 1rem;
        text-align: center;

    }
    .team-info__overview-goal__player-img{
        border-radius: 50%;
        overflow: hidden;
        margin-right: .5rem;
    }
      .team-info__overview-goal__player-goal{
        font-size: .85rem;
    }
</style>